import { matchPath, Navigate } from "react-router-dom";
import * as React from "react";

const ReactLazyPreload = (importStatement) => {
  const Component = React.lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};

import PageNotFound from "../404";
import UserLoginI18n from "../users/user_login";

const DataReportPage = ReactLazyPreload(() => import("../reports/data_report_page"));
const HomePageI18n = ReactLazyPreload(() => import("../homePage/home_page"));
const ReportsPage = ReactLazyPreload(() => import("../reports/reports_page"));
const RMPListPageI18n = ReactLazyPreload(() => import("../rmps/rmp_list_page"));
const RMP = ReactLazyPreload(() => import("../rmps/rmp"));
const RiskMapReportPage = ReactLazyPreload(() => import("../reports/risk_map_reports/risk_map_report_page"));
const RiskTablesReportPage = ReactLazyPreload(() => import("../reports/risk_tables/risk_tables_report_page"));
const ProcessMapReportPage = ReactLazyPreload(() => import("../reports/process_map_reports/process_map_report_page"));
const ProcessCapabilityDashboardPage = ReactLazyPreload(() => import("../reports/pcm_dashboard/pcm_dashboard_page"));
const ChartsReportPage = ReactLazyPreload(() => import("../reports/chart_reports/charts_report_page"));

const CompanyPage = ReactLazyPreload(() => import("../admin/internal/company_page"));
const AdminPanelListPage = ReactLazyPreload(() => import("../admin/internal/admin_internal_list_page"));

const BatchListPageI18n = ReactLazyPreload(() => import("../batches/batch_list_page"));
const BatchI18n = ReactLazyPreload(() => import("../batches/batch"));
const TrialExpiredPage = ReactLazyPreload(() => import("../billing/trial_expired_page"));
const ControlMethodsPageI18n = ReactLazyPreload(() => import("../controlMethod/control_methods_page"));
const ControlMethodsI18n = ReactLazyPreload(() => import("../controlMethod/control_method"));
const DocumentEditorPage = ReactLazyPreload(() => import("../documentEditor/document_editor_page"));
const DocumentListInProjectPageI18n = ReactLazyPreload(() => import("../documents/document_list_in_project_page"));
const DocumentListPageI18n = ReactLazyPreload(() => import("../documents/document_list_page"));
const Document = ReactLazyPreload(() => import("../documents/document"));
const ImportDashboardPage = ReactLazyPreload(() => import("../import/import_dashboard_page"));
const ImportFilePage = ReactLazyPreload(() => import("../import/import_file_page"));
const ImportProjectRecordPage = ReactLazyPreload(() => import("../import/import_project_record_page"));
const ImportResultReviewPage = ReactLazyPreload(() => import("../import/import_result_review_page"));
const LibraryMaterialI18n = ReactLazyPreload(() => import("../library/editor/library_material"));
const LinkProjectMaterialAttributePageI18n = ReactLazyPreload(() => import("../library/wizardActions/link/link_project_material_attribute_page"));
const UnlinkProjectMaterialAttributePageI18n
  = ReactLazyPreload(() => import("../library/wizardActions/unlink/unlink_project_material_attribute_page"));
const CreateProjectMaterialPageI18n
  = ReactLazyPreload(() => import("../library/wizardActions/createProjectMaterial/fromProject/create_project_material_page"));
const SwapProjectMaterialPageI18n = ReactLazyPreload(() => import("../library/wizardActions/swap/swap_project_material_page"));
const SyncProjectMaterialPageI18n = ReactLazyPreload(() => import("../library/wizardActions/sync/sync_project_material_page"));
const CompareProjectMaterialAttributePage
  = ReactLazyPreload(() => import("../library/wizardActions/compare/compare_project_material_attribute_page"));
const UnlinkProjectMaterialPageI18n = ReactLazyPreload(() => import("../library/wizardActions/unlink/unlink_project_material_page"));
const UnsyncProjectMaterialPageI18n = ReactLazyPreload(() => import("../library/wizardActions/unsync/unsync_project_material_page"));
const LibraryPageI18n = ReactLazyPreload(() => import("../library/library"));
const ProcessComponentI18n = ReactLazyPreload(() => import("../processExplorer/assets/process_component"));
const ProcessExplorerPageI18n = ReactLazyPreload(() => import("../processExplorer/process_explorer_page"));
const MaterialI18n = ReactLazyPreload(() => import("../processExplorer/assets/material"));
const UnitOperationPage = ReactLazyPreload(() => import("../features/process-explorer/pages/operations/UnitOperationPage"));
const IPAI18n = ReactLazyPreload(() => import("../processExplorer/variables/ipa"));
const IQAI18n = ReactLazyPreload(() => import("../processExplorer/variables/iqa"));
const LinkTechTransferPageI18n = ReactLazyPreload(() => import("../processExplorer/linkTechTransfer/link_tech_transfer_page"));
const MaterialAttributeI18n = ReactLazyPreload(() => import("../processExplorer/variables/material_attribute"));
const ProcessParameterI18n = ReactLazyPreload(() => import("../processExplorer/variables/process_parameter"));
const StepPage = ReactLazyPreload(() => import("../features/process-explorer/pages/operations/StepPage"));
const ProcessI18n = ReactLazyPreload(() => import("../processExplorer/parentPages/process"));
const ProductI18n = ReactLazyPreload(() => import("../product/product"));
const FQAPage = ReactLazyPreload(() => import("../features/process-explorer/pages/products/FQAPage"));
const FPAI18n = ReactLazyPreload(() => import("../product/editors/fpa"));
const GeneralAttributeI18n = ReactLazyPreload(() => import("../product/editors/general_attribute"));
const TPPSectionI18n = ReactLazyPreload(() => import("../product/editors/tppSection"));
const Project = ReactLazyPreload(() => import("../projects/project"));
const ProjectListPageI18n = ReactLazyPreload(() => import("../projects/project_list_page"));
const ProjectDashboardPage = ReactLazyPreload(() => import("../dashboard/project_dashboard_page"));
const BulkApprovalPage = ReactLazyPreload(() => import("../dashboard/approval/bulk_approval_page"));
const BulkProposalPage = ReactLazyPreload(() => import("../dashboard/proposal/bulk_proposal_page"));
const ProjectChangeRMPPage = ReactLazyPreload(() => import("../projects/project_change_rmp_page"));
const RestAPIPage = ReactLazyPreload(() => import("../restAPI/rest_api_page"));
const CheckI18n = ReactLazyPreload(() => import("../sysChecks/check"));
const TechTransferPage = ReactLazyPreload(() => import("../techTransfer/tech_transfer_page"));
const TrainingListPage = ReactLazyPreload(() => import("../training/list_pages/training_list_page"));
const TrainingI18n = ReactLazyPreload(() => import("../training/editors/training"));
const MyTrainingI18n = ReactLazyPreload(() => import("../training/my_training"));
const TrainingOnboardingI18n = ReactLazyPreload(() => import("../training/onboarding/training_onboarding"));
const CurriculumI18n = ReactLazyPreload(() => import("../training/editors/curriculum"));
const CurriculumListPage = ReactLazyPreload(() => import("../training/list_pages/curriculum_list_page"));
const SupplierListPage = ReactLazyPreload(() => import("../suppliers/supplier_list_page"));
const Supplier = ReactLazyPreload(() => import("../suppliers/supplier"));
const APIKey = ReactLazyPreload(() => import("../users/userRecordManagement/api_key"));
const UserAccountI18n = ReactLazyPreload(() => import("../users/accountManagement/user_create_account"));
const UserForgotPasswordI18n = ReactLazyPreload(() => import("../users/passwordManagement/user_forgot_password_page"));
const UserListPageI18n = ReactLazyPreload(() => import("../users/list/user_list_page"));
const UserResetPasswordI18n = ReactLazyPreload(() => import("../users/passwordManagement/user_reset_password_page"));
const UserSignupVerificationI18n = ReactLazyPreload(() => import("../users/accountManagement/user_signup_verification"));
const User = ReactLazyPreload(() => import("../users/userRecordManagement/user"));

export const routes = [{
  path: "/admin/internal/editCompany.html",
  component: CompanyPage,
  id: "company_page"
}, {
  path: "/admin/internal/listOfUsers.html",
  component: AdminPanelListPage,
  id: "adminInternalListPage"
}, {
  path: "/batches/list.html",
  component: BatchListPageI18n,
  id: "batchListPage"
}, {
  path: "/batches/viewEdit.html",
  component: BatchI18n,
  id: "batchPage"
}, {
  path: "/billing/trialExpired.html",
  component: TrialExpiredPage,
  id: "trialExpiredPage"
}, {
  path: "/controlMethods/list.html",
  component: ControlMethodsPageI18n,
  id: "controlMethodsPage"
}, {
  path: "/controlMethods/viewEdit.html",
  component: ControlMethodsI18n,
  id: "cmPage"
}, {
  path: "/documentEditor/documentEditor.html",
  component: DocumentEditorPage,
  id: "document-editor"
}, {
  path: "/documents/documentsListInProject.html",
  component: DocumentListInProjectPageI18n,
  id: "documentsListInProjectPage"
}, {
  path: "/documents/list.html",
  component: DocumentListPageI18n,
  id: "documentListPage"
}, {
  path: "/documents/viewEdit.html",
  component: Document,
  id: "documentPage"
}, {
  path: "/homePage/dashboard.html",
  component: HomePageI18n,
  id: "homePage"
}, {
  path: "/import/importDashboard.html",
  component: ImportDashboardPage,
  id: "importDashboardPage"
}, {
  path: "import/importFile.html",
  component: ImportFilePage,
  id: "importFilePage"
}, {
  path: "/import/importProject.html",
  component: ImportProjectRecordPage,
  id: "importProjectRecordPage"
}, {
  path: "/import/importResultReview.html",
  component: ImportResultReviewPage,
  id: "ImportResultReviewPage"
}, {
  path: "/library/materials/viewEdit.html",
  component: LibraryMaterialI18n,
  id: "libraryMaterialPage"
}, {
  path: "/library/projectMaterialAttributes/compare.html",
  component: CompareProjectMaterialAttributePage,
  id: "compareProjectMaterialAttributePage"
}, {
  path: "/library/projectMaterialAttributes/link.html",
  component: LinkProjectMaterialAttributePageI18n,
  id: "linkProjectMaterialAttributePage"
}, {
  path: "/library/projectMaterialAttributes/unlink.html",
  component: UnlinkProjectMaterialAttributePageI18n,
  id: "unlinkProjectMaterialAttributePage"
}, {
  path: "/library/projectMaterials/create.html",
  component: CreateProjectMaterialPageI18n,
  id: "createProjectMaterialPage"
}, {
  path: "/library/projectMaterials/swap.html",
  component: SwapProjectMaterialPageI18n,
  id: "swap-project-material-page"
}, {
  path: "/library/projectMaterials/sync.html",
  component: SyncProjectMaterialPageI18n,
  id: "sync-project-material-page"
}, {
  path: "/library/projectMaterials/unlink.html",
  component: UnlinkProjectMaterialPageI18n,
  id: "unlinkProjectMaterialPage"
}, {
  path: "/library/projectMaterials/unsync.html",
  component: UnsyncProjectMaterialPageI18n,
  id: "unsyncProjectMaterialPage"
}, {
  path: "/library/list.html",
  component: LibraryPageI18n,
  id: "library-list-page",
  className: "full-screen-display"
}, {
  path: "/processExplorer/ipas/viewEdit.html",
  component: IPAI18n,
  id: "ipaPage"
}, {
  path: "/processExplorer/iqas/viewEdit.html",
  component: IQAI18n,
  id: "iqaPage"
}, {
  path: "/processExplorer/linkTechTransfer/link.html",
  component: LinkTechTransferPageI18n,
  id: "linkTechTransferPage"
}, {
  path: "/processExplorer/materialAttributes/viewEdit.html",
  component: MaterialAttributeI18n,
  id: "materialAttributePage"
}, {
  path: "/processExplorer/materials/viewEdit.html",
  component: MaterialI18n,
  id: "materialPage"
}, {
  path: "/processExplorer/processComponents/viewEdit.html",
  component: ProcessComponentI18n,
  id: "processComponentPage"
}, {
  path: "/processExplorer/processes/viewEdit.html",
  component: ProcessI18n,
  id: "processPage"
}, {
  path: "/processExplorer/processParameters/viewEdit.html",
  component: ProcessParameterI18n,
  id: "processParameterPage",
}, {
  path: "/processExplorer/steps/viewEdit.html",
  component: StepPage,
  id: "stepPage"
}, {
  path: "/processExplorer/unitOperations/viewEdit.html",
  component: UnitOperationPage,
  id: "unitOperationPage"
}, {
  path: "/processExplorer/detailed.html",
  component: ProcessComponentI18n,
  id: "processExplorerDetailedContainer"
}, {
  path: "/processExplorer/processExplorer.html",
  component: ProcessExplorerPageI18n,
  className: "full-screen-display",
  id: "processExplorerPage"
}, {
  path: "/product/qtpp/fpas/viewEdit.html",
  component: FPAI18n,
  id: "fpaPage"
}, {
  path: "/product/qtpp/fqas/viewEdit.html",
  component: FQAPage,
  id: "fqaPage"
}, {
  path: "/product/qtpp/generalAttributes/viewEdit.html",
  component: GeneralAttributeI18n,
  id: "generalAttributeViewEditPage"
}, {
  path: "/product/tpp/viewEdit.html",
  component: TPPSectionI18n,
  id: "tppViewEditPage"
}, {
  path: "/product/list.html",
  component: ProductI18n,
  id: "productPage",
  className: "full-screen-display"
}, {
  path: "/projects/bulkApproval.html",
  component: BulkApprovalPage,
  id: "bulkApprovalPage"
}, {
  path: "/projects/bulkProposal.html",
  component: BulkProposalPage,
  id: "bulkProposalPage"
}, {
  path: "/projects/changeRMP.html",
  component: ProjectChangeRMPPage,
  id: "projectChangeRMPPage"
}, {
  path: "/projects/dashboard.html",
  component: ProjectDashboardPage,
  id: "projectDashboardPage"
}, {
  path: "/projects/list.html",
  component: ProjectListPageI18n,
  id: "projectListPage"
}, {
  path: "/projects/viewEdit.html",
  component: Project,
  id: "projectPage"
}, {
  path: "/reports/cannedReports/base.html",
  component: DataReportPage,
  id: "cannedReportPage"
}, {
  path: "/reports/chartReports.html",
  component: ChartsReportPage,
  id: "chartReportsPage"
}, {
  path: "/reports/list.html",
  component: ReportsPage,
  id: "reportsPage"
}, {
  path: "/reports/processCapabilityDashboard.html",
  component: ProcessCapabilityDashboardPage,
  id: "processCapabilityDashboard"
}, {
  path: "/reports/processMapReports.html",
  component: ProcessMapReportPage,
  id: "processMapReportsPage",
  className: "full-screen-display"
}, {
  path: "/reports/riskMap.html",
  component: RiskMapReportPage,
  id: "riskMapReportPage",
  className: "full-screen-display"
}, {
  path: "/reports/riskTables.html",
  component: RiskTablesReportPage,
  id: "riskTablesReportPage"
}, {
  path: "/restAPI/index.html",
  component: RestAPIPage,
  id: "restAPIPage"
}, {
  path: "/rmps/list.html",
  component: RMPListPageI18n,
  id: "rmpListPage"
}, {
  path: "/rmps/viewEdit.html",
  component: RMP,
  id: "rmpPage"
}, {
  path: "/suppliers/viewEdit.html",
  component: Supplier,
  id: "supplierPage"
}, {
  path: "/suppliers/list.html",
  component: SupplierListPage,
  id: "supplierListPage"
}, {
  path: "/sysChecks/check.html",
  component: CheckI18n,
  id: "check"
}, {
  path: "/techTransfer/techTransfer.html",
  component: TechTransferPage,
  id: "techTransferPage",
  className: "full-screen-display"
}, {
  path: "/training/curricula/list.html",
  component: CurriculumListPage,
  id: "curriculumListPage"
}, {
  path: "/training/curricula/viewEdit.html",
  component: CurriculumI18n,
  id: "curriculumPage"
}, {
  path: "/training/list.html",
  component: TrainingListPage,
  id: "trainingListPage"
}, {
  path: "/training/myTraining.html",
  component: MyTrainingI18n,
  id: "myTrainingPage"
}, {
  path: "/training/onboardingAdmin.html",
  component: TrainingOnboardingI18n,
  id: "trainingOnboardingPage"
}, {
  path: "/training/viewEdit.html",
  component: TrainingI18n,
  id: "trainingPage"
}, {
  path: "/users/apiKeys/viewEdit.html",
  component: APIKey,
  id: "apiKeyPage"
}, {
  path: "/users/createCompany.html",
  component: UserAccountI18n,
  id: "createAccountPage"
}, {
  path: "/users/createAccount.html",
  component: <Navigate to="/users/createCompany.html" />,
  ignoreLazy: true
}, {
  path: "/users/forgotPassword.html",
  component: UserForgotPasswordI18n,
  id: "forgotPasswordPage"
}, {
  path: "/users/list.html",
  component: UserListPageI18n,
  id: "userListPage"
}, {
  path: "/users/resetPassword.html",
  component: UserResetPasswordI18n,
  id: "resetPasswordPage"
}, {
  path: "/users/signupVerification.html",
  component: UserSignupVerificationI18n,
  id: "signupVerification"
}, {
  path: "/users/viewEdit.html",
  component: User,
  id: "userPage"
}, {
  path: "/",
  component: UserLoginI18n,
  isLazy: false,
  id: "loginPage"
}, {
  path: "/index.html",
  component: UserLoginI18n,
  isLazy: false,
  id: "loginPage"
}, {
  path: "*",
  component: PageNotFound,
  isLazy: false,
  id: "pageNotFound"
}];

export const findComponentForRoute = (path, routes) => {
  if (!path) {
    return null;
  }

  if (path.includes("?")) {
    path = path.split("?")[0];
  }

  const matchingRoute = routes.filter(route => !["/", "*", "index,.html"].includes(route.path)).find((route) =>
    matchPath({
      path: route.path,
      exact: false,
      strict: false
    }, path)
  );

  return matchingRoute ? matchingRoute.component : null;
};

export const preloadRouteComponent = (path) => {
  const component = findComponentForRoute(path, routes);

  if (component && component.preload) {
    component.preload().then(() => {
      console.log("module loaded for path ", path);
    });
  }
};